<template>
<div>
  <b-form class="m-4">
    <div>Params:</div>
    <b-checkbox v-model="showGUM">showGUM</b-checkbox>
    <b-checkbox v-model="useStream">Stream</b-checkbox>
    <b-checkbox v-model="useSVG">SVG</b-checkbox>
    <b-checkbox v-model="debugMode">Debug Mode</b-checkbox>
    <b-checkbox v-model="mute">Mute</b-checkbox>
    <b-checkbox v-model="noAudioMerge">No Audio Merge</b-checkbox>
    <b-input v-model="userName" style="width: 200px;"/>
    <b-input v-model="userShortBio" style="width: 200px;"/>
  </b-form>
  <div class="ml-4 mt-2">
    <button class="btn btn-primary mr-2" @click="start">Start</button>
    <button class="btn btn-primary" @click="stop">Stop</button>
  </div>
  <webrtc-2
    :user="{id:'test'}"
    :room="{id:'test', video:true, startMuted:true, users:['test']}"
    @usersChange="webrtcUsersUpdate"
  />
  <div v-if="showGUM">
    GUM Video:<br/>
    <video
      v-if="users && users[0] && users[0].stream"
      style="border: 1px solid #000; width:25%"
      :srcObject.prop="users[0].stream"
      autoplay playsinline
    />
  </div>
  <svg-generator
    ref="svgGenerator"
  />
  <div>
  Composed Stream:<br/>
  <video
    v-if="final"
    style="border: 1px solid #000; width: 50%"
    :srcObject.prop="final"
    autoplay playsinline
  /><br/>
  <streaming-editor 
    class="m-2 mb-5"
    v-if="final" :config="{canvasId:'VSMcanvas'}"/>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog("composition");
import { VideoStreamMerger } from "@/components/video-stream-merger";
import Webrtc2 from '@/components/webrtc2.vue';
import StreamingEditor from '@/components/streamRecorder.vue';
import SvgGenerator from '@/components/svgGenerator.vue';

export default {
  components: {
    Webrtc2,
    StreamingEditor,
    SvgGenerator,
  },
  data() {
    return {
      // Options
      showGUM: true,
      useStream: true,
      useSVG: true,
      debugMode: true,
      mute: false,
      noAudioMerge: false,
      userName: "Laurent Mascherpa",
      userShortBio: "CEO, Runyon Canyon Corporation",

      // Working
      users: [],
      merger: null,
      final: null,

      svg: `<svg ref="svgId" viewBox="0 0 500 80" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" x1="11.141" y1="73.978" x2="11.141" y2="134.752" id="gradient-0" gradientTransform="matrix(1, 0, 0, 1.316353, -1.396, -97.381128)">
        <stop offset="0" style="stop-color: rgb(65, 151, 165);"/>
        <stop offset="1" style="stop-color: rgb(33, 106, 118);"/>
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" x1="260.328" y1="73.755" x2="260.328" y2="135.285" id="gradient-1" gradientTransform="matrix(1.005207, 0, 0, 1.300179, -1.938999, -95.894685)">
        <stop offset="0" style="stop-color: rgb(255, 255, 255);"/>
        <stop offset="1" style="stop-color: rgb(196, 196, 196);"/>
      </linearGradient>
      <style>@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);</style>
    </defs>
    <rect width="19.49" height="80" style="fill: url(#gradient-0);"/>
    <rect x="19.49" width="480.51" height="80" style="fill: url(#gradient-1);"/>
    <text style="fill: rgb(31, 64, 69); font-family: 'Roboto Slab'; font-size: 28px; white-space: pre;" x="26" y="32.431">%{user.name}</text>
    <text style="fill: rgb(65, 151, 164); font-family: 'Roboto Slab'; font-size: 20px; white-space: pre;" x="26" y="65.443">%{user.shortBio}</text>
  </svg>`,
      svgText: null,
      svgImage: null,
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.stop();
  },
  methods: {
    webrtcUsersUpdate(users) {
      log.log("webrtcUsersUpdate", users);
      this.users = users;
    },
    init() {
      this.createSVG();
    },
    async createVSM() {
      try {
        let params = {
          debug: this.debugMode,
          canvasId: "VSMcanvas",
          noAudioMerge: this.noAudioMerge,
        }
        log.log("VSM() params=", params);
        this.merger = new VideoStreamMerger(params);
      } catch (e) {
        log.error("VSM", e);
      }
    },
    async createSVG() {
      let data = {
        user: {
          name: this.userName,
          shortBio: this.userShortBio,
        }
      }
      this.image = await this.$refs.svgGenerator.imageFromSVG(this.svg, {width: 1024, height:160}, data);
    },
    async stop() {
      await this.merger.destroy();
      this.final = null;
      this.createVSM();
    },
    async start() {
      await this.createSVG();
      await this.createVSM();
      try {
        log.log("start");
        
        log.log("stream=", this.users[0].stream);
        if (this.useStream) {
          // Add the screen capture. Position it to fill the whole stream (the default)
          this.merger.addStream(this.users[0].stream, {
              x: 0, // position of the topleft corner
              y: 0,
              width: this.merger.width,
              height: this.merger.height,
              mute: this.mute,
            });
          this.merger.addStream(this.users[0].stream, {
              x: 50, // position of the topleft corner
              y: 50,
              width: this.merger.width - 100,
              height: this.merger.height - 100,
              mute: true // we don't want sound from the screen (if there is any)
            });
        }
        if (this.useSVG) {
          // Add the webcam stream. Position it on the bottom left and resize it to 100x100.
          this.merger.addMediaElement("svg", this.image, {
            x: 0,
            y: 0,
            width: this.merger.width,
            height: 200,
            muted: true
          });
        }

        // Start the merging. Calling this makes the result available to us
        this.merger.start();
      }
      catch (e) {
        log.error("VSM", e);
      }

      // We now have a merged MediaStream!
      log.log("merger result", this.merger.result);
      this.final = this.merger.result;
    }
  }
}
</script>

<style scoped>

video {
  width: 400px;
}

</style>
